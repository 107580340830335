@import "../../sass/base";

.gnp_tabs__container {
  display: flex;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    justify-content: center; }
  align-items: center;

  & > .gnp_tabs__button {
    display: block;
    @media (min-width: 768px) {
      padding: 0 40px; }
    &.--padding > .gnp_button {
      padding: 0 15px; } }
  & > .gnp_tabs__button > .gnp_button {
    max-width: 200px;
    width: 100%;
    padding: 0 5px;
    @media (min-width: 768px) {
      padding: 0 10px; } } }
