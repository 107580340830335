@import "../../sass/colors";

.gnp_security_code {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > &__input {
    color: #000000;
    //max-width: 38px
    width: 100%;
    font-size: 61px;
    border: 1px solid #000000;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }

    &[type=number] {
      -moz-appearance: none; } } }
