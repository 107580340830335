@import "../../sass/base";

.gnp_report_filter_container {
  width: 100%;
  margin: 30px;
  margin-top: 0px;

  @include flex(flex-start, center); }


.gnp_report_filter_container > .gnp_report_filter_container__links {
  & > a {
    margin: 0 10px;
    text-decoration: none;
    color: $doveGray;
    &.--active {
      //text-decoration: underline
      color: black;
      font-weight: bold; } } }

.gnp_report_download_container > a {
  color: #E2871A; }
