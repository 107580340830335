@import "colors";
@import "mixins";

* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif; }

.gnp_container_full {
  width: 100%;
  margin: 0;
  padding: 0; }


.gnp_page_wrapper {
  &.--file-upload {
    width: 100%; }
  &.--table-bar {
    border-bottom: 1px solid #CCCCCC; }
  &.--vertical {
    margin: 27px 0; }
  &.--flex-center {
    @include flex-center; }

  &.--table {
    width: 100%;
    max-width: 1040px;
    @include flex-center; }

  &.--table-container {
    margin: 0 50px;
    flex-direction: column; } }


.gnp_icon_button {
  width: 25px;
  height: 25px;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none; }
  &.--delete {
    color: $fireEngineRed; } }
