@import "../../sass/base";

.gnp_table_paginator {
  @include flex(space-between, center);
  padding: 6px 16px;
  min-height: 49px;

  & > &__left_content {
    @include flex-center; }

  &__links {
    @include flex-center; }


  &__counter {
    margin-right: 16px; } }

.gnp_table_paginator > .gnp_table_paginator__left_content > .gnp_table_paginator__links > .gnp_table_paginator__arrow {
    & > .gnp_table_paginator__arrow_button {
      cursor: pointer;
      font-size: 20px;
      color: $doveGray;
      margin-right: 10px;
      background: transparent;
      border: none;

      &:focus {
        outline: none; } } }
