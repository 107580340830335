@import "../../sass/base";

.gnp_company_tabs {
  max-width: 1130px;
  width: 100%;
  display: block;
  margin: 0 auto;
  & > &__title {
    margin-top: 50px;
    text-align: center;
    font-size: 26px;
    font-weight: bold; }
  & > &__content {
    padding: 0 15px; }
  & > &__tabs {
    display: flex;
    margin: 40px 15px;
    border-bottom: 1px solid $doveGray;
    & > .gnp_company_tabs__link {
      display: block;
      padding: 10px;
      margin-right: 10px;
      border: 1px solid $doveGray;
      border-bottom: none;
      background-color: #f8f8f8f8;
      color: $doveGray;
      text-decoration: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      &.--active {
        color: #FFFFFF;
        background-color: $darkCarrotOrange; } } } }
