@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

@mixin flex($justifyContent, $alignItems) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems; }

@mixin modal-padding {
  padding: 0 30px; }
