@import "../../sass/base";

.gnp_toaster {
  width: 100%;
  max-width: 768px;
  color: #FFFFFF;
  position: absolute;
  bottom: 0;
  padding: 15px 0;
  display: block;
  transition: all linear 500ms;
  @include flex(space-between, center);
  &.--hidden {
    right: -1000px; }

  &__close_button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    &:focus {
      outline: none; } }

  @media (min-width: 768px) {
    padding: 15px 40px;
    right: 0;
    bottom: 1em;
    border-top-left-radius: 99px;
    border-bottom-left-radius: 99px; }

  .gnp_toaster__text {
    margin-left: 10px;
    @media (min-width: 768px) {
      margin-left: 0; } } }
