@import "../../sass/base";

.gnp_company_create_form {
  max-width: 850px;
  padding: 40px;
  border: 1px solid $doveGray;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  & > &__title {
    border-bottom: 1px solid $doveGray; }
  & > &__form {
    margin-top: 40px; }

  .gnp_company_create_form__submit {
    max-width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 40px; } }


