$summerSky: #3895EA;
$veryLightGrey: #CCCCCC;
$prussianBlue: #004380;
$carrotOrange: #F08525;
$darkCarrotOrange: #E2871A;
$fireEngineRed: #B91818;
$darkPastelGreen: #00E22D;
$white: #FFF;
$doveGray: #707070;
$gallery: #EBEBEB;
$laPalma: #17AF17;

$colors: ("summerSky": $summerSky, "veryLightGrey": $veryLightGrey, "prussianBlue": $prussianBlue, "carrotOrange": $carrotOrange, "darkCarrotOrange": $darkCarrotOrange, "fireEngineRed": $fireEngineRed, "darkPastelGreen": $darkPastelGreen, "white": $white, "doveGray": $doveGray, "galery": $gallery, "laPalma": $laPalma);

@each $color, $value in $colors {
  .gnp_color_#{$color} {
    color: $value; }
  .gnp_background_#{$color} {
    background-color: $value; }
  .gnp_border_#{$color} {
    border-color: $value; } }
