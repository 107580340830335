@import "../../sass/colors";

.gnp_button {
  width: 100%;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 20px;
  text-decoration: none;

  &.--filled {
    border: none;
    color: $white; }
  &.--primary {
    height: 50px; }
  &.--small {
    height: 37px; }
  &.--tiny {
    height: initial; }

  &.--outlined {
    border-width: 3px;
    border-style: solid;
    border-radius: 100px;
    padding: 0; }

  & > .gnp_icon {
    &.--left {
      margin-right: 10px; }
    &.--right {
      margin-left: 10px; } } }
