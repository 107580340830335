@import "../../sass/base";

.gnp_employee_file_upload {
  width: 100%;
  @include flex(flex-end, center);
  margin-bottom: 15px;

  & > .gnp_employee_file_upload__form {
    @include flex(flex-end, center);
    width: 100%; } }

.gnp_employee_file_upload > .gnp_employee_file_upload__form {
  & > .gnp_employee_file_upload__label {
    margin-right: 15px; }
  & > .gnp_employee_file_upload__submit {
    width: 100%;
    max-width: 99px;
    padding: 5px;
    background-color: #CCCCCC;
    border: none;
    color: $doveGray; } }

.gnp_employee_file_upload > .gnp_employee_file_upload__form > .gnp_employee_file_upload__wrapper {
  width: 100%;
  max-width: 200px;
  @include flex(flex-end, center);
  flex-direction: column;
  position: relative;
  margin-right: 10px;
  //z-index: -9
  & > .gnp_employee_file_upload__error {
    position: absolute;
    top: -25px;
    width: 100%;
    display: block;
    text-align: center; }
  & > .gnp_employee_file_upload__file {
    padding: 4px;
    border: 1px solid $doveGray;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus {
      outline: none; } }

  & > .gnp_employee_file_upload__submit {
    &.--hidden {
      display: none; } } }
