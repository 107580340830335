@import "../../sass/base";

.gnp_table_bar {
  @include flex(space-between, center);
  padding: 13px 16px 6px 16px;

  .gnp_button__wrapper {
    max-width: 110px;
    & button {
      padding: 5px 20px; } } }
