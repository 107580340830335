@import "../../sass/colors";

.gnp_text_input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #CECECE;
  border-radius: 5px;
  color: #A4A4A4;
  height: 35px;

  &__icon_container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    border-right: 2px solid #CECECE;
    margin-right: 5px;
    max-width: 36px;

    & > .gnp_text_input__icon {
      position: relative;
      display: block;
      padding: 0 5px; } }

  &__input {
    border: none;
    font-size: 14px;
    width: 100%;
    color: #A4A4A4;
    padding: 5px;
    &:focus {
      outline: none; } } }
