@import "../../sass/base";

.gnp_modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#D8D8D8, 0.64);
  @include flex-center;
  z-index: 1; }

.gnp_modal__container {
  width: 100%;
  max-width: 420px;

  .gnp_modal__spacing {
    padding: 30px;

    .gnp_modal__children {
      & > form > * {
        margin: 15px 0; }
      .gnp_modal__submit_button {
        @include flex(flex-end, center);
        margin-top: 30px; } }

    & > .gnp_modal__header {
      display: block;
      width: 100%;
      @include flex(space-between, center);
      margin-bottom: 25px;

      & > .gnp_modal__header__close {
        color: red;
        cursor: pointer;
        font-size: 25px; } } } }
