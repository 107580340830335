@import "../../sass/base";

.gnp_navigation_bar {
  width: 100%;
  background-color: $gallery;
  height: 115px;
  @include flex-center;

  & > &__wrapper {
    @include flex(space-between, center);
    width: 100%;
    margin: 0 10px;
    @media (min-width: 768px) {
      margin: 0 100px; } } }


.gnp_navigation_bar > .gnp_navigation_bar__wrapper {

  & > .gnp_navigation_bar__profile {
    width: 50px;
    height: 50px;
    background-color: $white;
    border-radius: 10000px;
    border: 2px solid $doveGray;
    color: $doveGray;
    @include flex-center;
    cursor: pointer;
    font-size: 25px;
    position: relative;

    & > .gnp_navigation_bar__menu {
      display: none; }
    &:hover > .gnp_navigation_bar__menu {
      position: absolute;
      left: -150px;
      background-color: $prussianBlue;
      padding: 10px;
      color: #FFFFFF;
      display: block;
      border-radius: 5px;
      font-size: 12px;

      &:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 17.3px;
        border-color: transparent transparent transparent $prussianBlue;
        position: absolute;
        right: -8px;
        top: calc(50% - 5px); } }

    & > .gnp_navigation_bar__menu > .gnp_navigation_bar__link {
      margin-top: 5px;
      & > a {
        font-size: {}
        color: $darkCarrotOrange; } } } }
